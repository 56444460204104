<template>
  <div>
    <leaflet-panel
      :body="body"
      :image="image"
      :show-buy-btn="showBuyBtn"
      :show-detail-btn="showDetailBtn"
      :title="title"
      :mark="mark"
    />
    <!-- <ImageList :images="images"></ImageList> -->

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
      :title="`平台特色与优势`"
      :en_title="`Platform Features & Advantages`"
    ></Paragraph>

    <Titlenewlinelist
      :datalist="datalist"
      style="margin-bottom: 6rem"
    ></Titlenewlinelist>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
      :title="`应用场景`"
      :en_title="`Application Scenarios`"
    ></Paragraph>
    <Logotitlebox :datalist="datalistx1"></Logotitlebox>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
      :title="`服务价值`"
      :en_title="`Service Value`"
    ></Paragraph>
    <div
      style="
        margin-left: 10%;
        margin-right: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    >
      <Logotitlebox
        :datalist="datalistx"
        style="display: flex; flex-direction: column; align-items: center"
      ></Logotitlebox>
    </div>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Logotitlebox from '@/components/chain/basic/common/logotitlebox';
import Titlenewlinelist from '@/components/chain/basic/common/titlenewlinelist';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Logotitlebox,
    Titlenewlinelist,
  },
  data() {
    return {
      title: '司法链服务',
      body:
        '司法链是基于全球领先的区块链技术构建内外网一体的开放式司法链平台，提供司法可信服务，'
        + '实现司法活动中的电子数据可核验、可追溯，助力司法改革，全面提升法院司法能力、司法效能和司法公信力。',
      image: require('@/assets/image/chain/alliance/justice/justice.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/alliance/justice/19_01.jpg'),
        require('@/assets/image/chain/alliance/justice/19_02.jpg'),
        require('@/assets/image/chain/alliance/justice/19_03.jpg'),
      ],
      mark: '1',
      datalist: [
        {
          title: '全链路可信',
          content:
            '基于可信身份，可信环境，可信时间等司法可信服务，解决“路上”到“链上”的最后一公里。',
        },
        {
          title: '全节点见证',
          content:
            '基于全球领先的区块链技术，由各级人民法院会同公证处、司法鉴定机构等共同作为区块链节点，见证链上活动。',
        },
        {
          title: '全流程留痕',
          content:
            '各种法律行为发生的事前、事中、事后全过程链上留痕，形成完整证据链。',
        },
      ],
      datalistx1: [
        {
          imageurl: require('@/assets/image/contentsvg/fly.svg'),
          title: '数据上链存证',
          list: ['将各类数据上链，诉讼中可作为', '电子数据证据'],
        },
        {
          imageurl: require('@/assets/image/contentsvg/book.svg'),
          title: '司法链数据核验',
          list: ['基于已上链数据', '验证电子数据证据效力'],
        },
      ],
      datalistx: [
        {
          imageurl: require('@/assets/image/contentsvg/people.svg'),
          title: '服务人民群众',
          list: ['“司法红绿灯”，强化诉讼源头治理'],
        },
        {
          imageurl: require('@/assets/image/contentsvg/tie.svg'),
          title: '服务审批执行',
          list: ['“司法快车道”，诉讼全流程换挡提速'],
        },
        {
          imageurl: require('@/assets/image/contentsvg/star.svg'),
          title: '服务司法管理',
          list: ['“司法安全锁”，全方位助力科学管理'],
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
